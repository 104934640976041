define("fuel-portal/components/ui/form/control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes
    class="space-y-1">
    {{yield (hash
      Input=(component "ui/form/input" name=@name invalid=@invalid)
      DateRange=(component "ui/form/date-range" name=@name invalid=@invalid)
      Dropdown=(component "ui/form/dropdown" name=@name invalid=@invalid)
      MultiDropdown=(component "ui/form/multi-dropdown" name=@name invalid=@invalid)
      Label=(component "ui/form/label" name=@name invalid=@invalid)
      Tip=(component "ui/form/tip" name=@name invalid=@invalid)
      Error=(component "ui/form/error" name=@name invalid=@invalid)
    )}}
  </div>
  */
  {"id":"7WrSvwh5","block":"{\"symbols\":[\"&attrs\",\"@invalid\",\"@name\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"space-y-1\"],[12],[2,\"\\n  \"],[18,4,[[30,[36,1],null,[[\"Input\",\"DateRange\",\"Dropdown\",\"MultiDropdown\",\"Label\",\"Tip\",\"Error\"],[[30,[36,0],[\"ui/form/input\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/date-range\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/dropdown\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/multi-dropdown\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/label\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/tip\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]],[30,[36,0],[\"ui/form/error\"],[[\"name\",\"invalid\"],[[32,3],[32,2]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/form/control.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});