define("fuel-portal/mirage/factories/transaction", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    date: function date() {
      return _faker.default.date.between("2019-08-01", "2019-08-20");
    },
    locationName: function locationName() {
      return _faker.default.company.companyName();
    },
    address: function address() {
      return [_faker.default.address.streetAddress(), _faker.default.address.city(), _faker.default.address.county(), _faker.default.address.zipCode()].join(", ");
    },
    lat: function lat() {
      // uk bounds (min/max)
      return _faker.default.random.number({
        min: 50747318,
        max: 53784832
      }) / 1000000;
    },
    lng: function lng() {
      // uk bounds (min/max)
      return _faker.default.random.number({
        min: -2902081,
        max: -178663
      }) / 1000000;
    },
    reference: function reference() {
      return _faker.default.random.uuid();
    },
    offset: function offset() {
      return _faker.default.random.number();
    },
    productName: function productName() {
      return _faker.default.random.arrayElement(['DIESEL', 'PETROL']);
    },
    netValue: function netValue() {
      return _faker.default.finance.amount(40, 900);
    },
    taxValue: function taxValue() {
      return this.netValue * 0.2;
    },
    quantity: function quantity() {
      return _faker.default.random.number({
        min: 30,
        max: 900
      });
    },
    receipt: function receipt() {
      return _faker.default.random.arrayElement(['/assets/images/receipt1.jpg', '/assets/images/receipt2.jpg', '/assets/images/receipt3.jpg']);
    },
    withCard: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(transaction, server) {
        var card = _faker.default.random.arrayElement(server.schema.cards.all().models);

        if (card) {
          transaction.update({
            card: card,
            account: card.account
          });
        }
      }
    }),
    withInvoice: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(transaction, server) {
        var invoice = _faker.default.random.arrayElement(server.schema.invoices.all().models);

        if (invoice) {
          transaction.update({
            invoice: invoice
          });
        }
      }
    })
  });

  _exports.default = _default;
});