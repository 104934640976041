define("fuel-portal/utils/cards/card-preview-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cardPreviewPath;

  function cardPreviewPath(cardType) {
    switch (cardType) {
      case "BP LIST":
        return "card/preview/bplist";

      case "BP BUNKER":
        return "card/preview/bpbunker";

      case "BP PLUS":
        return "card/preview/bpplus";

      case "SHELL FLT":
        return "card/preview/shellflt";

      case "SHELLFLT2":
        return "card/preview/shellflt2";

      case "SHELL CRT":
        return "card/preview/shellcrt";

      case "SHELLMULTI":
        return "card/preview/shellmulti";

      case "SHELLPRIME":
        return "card/preview/shellprime";

      case "UK FUELS":
        return "card/preview/ukfuels";

      case "ESSO":
        return "card/preview/esso";

      case "ESSO TRUCK":
        return "card/preview/essotruck";

      case "FUELGENIE":
        return "card/preview/fuelgenie";

      case "D DIRECT":
        return "card/preview/ddirect";

      case "FASTFUELS":
        return "card/preview/fastfuels";

      default:
        return "card/preview/default";
    }
  }
});