define("fuel-portal/templates/internal/account/journey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uZDSUMgT",
    "block": "{\"symbols\":[\"header\"],\"statements\":[[8,\"ui/account/crumbs\",[],[[\"@page\",\"@account\",\"@loading\"],[\"Journey Planner\",[32,0,[\"account\"]],[32,0,[\"loading\"]]]],null],[2,\"\\n\\n\"],[8,\"ui/header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[\"@text\"],[\"Journey Planner\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"px-6 lg:px-18 flex flex-col space-y-4 mb-4\"],[12],[2,\"\\n    \"],[8,\"ui/card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ui/action-buttons\",[],[[\"@actions\",\"@model\"],[[32,0,[\"accountActions\"]],[32,0,[\"account\",\"id\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"relative flex flex-col flex-auto\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"pointer-events-none absolute w-full inset-0 flex flex-col justify-center items-center \",[30,[36,0],[[32,0,[\"showFrame\"]],\"opacity-0\",\"opacity-100\"],null]]]],[12],[2,\"\\n    \\n        \"],[1,[30,[36,1],[\"loading\"],[[\"class\"],[\"w-8 h-8\"]]]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-lg text-gray-500 text-center py-2\"],[12],[2,\"Loading Journey Planner\"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"iframe\"],[16,\"src\",[32,0,[\"iframeSrc\"]]],[24,\"title\",\"Journey Planner\"],[24,1,\"journey-planner\"],[16,0,[31,[\"w-full flex-auto transition-opacity duration-500 ease-in-out  \",[30,[36,0],[[32,0,[\"showFrame\"]],\"opacity-100\",\"opacity-0\"],null]]]],[4,[38,2],[\"load\",[32,0,[\"iframeLoaded\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"h-6\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"svg-jar\",\"on\"]}",
    "meta": {
      "moduleName": "fuel-portal/templates/internal/account/journey.hbs"
    }
  });

  _exports.default = _default;
});