define("fuel-portal/utils/feature-enabled", ["exports", "fuel-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = featureEnabled;

  function featureEnabled(feature) {
    return _environment.default[feature + 'Enabled'];
  }
});