define("fuel-portal/utils/vat-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vatAmountFromGross = vatAmountFromGross;
  _exports.vatAmountFromNet = vatAmountFromNet;
  _exports.VAT_RATE = void 0;
  var VAT_RATE = 0.20;
  _exports.VAT_RATE = VAT_RATE;

  function vatAmountFromGross(grossAmount) {
    var emptyString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "n/a";
    grossAmount = parseFloat(grossAmount);

    if (isNaN(grossAmount)) {
      return emptyString;
    }

    if (grossAmount > 0) {
      return grossAmount / (1 + 1 / VAT_RATE);
    }

    return 0;
  }

  function vatAmountFromNet(netAmount) {
    var emptyString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "n/a";
    netAmount = parseFloat(netAmount);

    if (isNaN(netAmount)) {
      return emptyString;
    }

    if (netAmount > 0) {
      return netAmount * (1 + VAT_RATE) - netAmount;
    }

    return 0;
  }
});