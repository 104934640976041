define("fuel-portal/components/ui/transaction/volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-col justify-center items-center p-6">
  
    <h1 class="text-base font-bold leading-5">Transaction Details</h1>
  
    {{#if @loading}}
      <div class="my-4 h-20 bg-primary-100 rounded-lg w-1/2"></div>
    {{else}}
      <div class="truncate w-full text-center text-4xl sm:text-6xl text-primary-600 font-bold font-serif my-4 leading-tight">
        {{format-volume @transaction.amountQuantity}}
      </div>
    {{/if}}
  
    <div class="text-base font-bold leading-5">Fuel</div>
  
  </div>
  */
  {"id":"YGgpTKXg","block":"{\"symbols\":[\"@transaction\",\"@loading\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-col justify-center items-center p-6\"],[12],[2,\"\\n\\n  \"],[10,\"h1\"],[14,0,\"text-base font-bold leading-5\"],[12],[2,\"Transaction Details\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"my-4 h-20 bg-primary-100 rounded-lg w-1/2\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"truncate w-full text-center text-4xl sm:text-6xl text-primary-600 font-bold font-serif my-4 leading-tight\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,1,[\"amountQuantity\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-base font-bold leading-5\"],[12],[2,\"Fuel\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format-volume\",\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/transaction/volume.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});