define("fuel-portal/utils/const/journey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JOURNEY_LOAD_TIME = _exports.JOURNEY_IFRAME = void 0;
  var JOURNEY_IFRAME = "https://fuelmate-selfservice.bytestrype.com/index.html?embed=true";
  _exports.JOURNEY_IFRAME = JOURNEY_IFRAME;
  var JOURNEY_LOAD_TIME = 5000;
  _exports.JOURNEY_LOAD_TIME = JOURNEY_LOAD_TIME;
});