define("fuel-portal/components/ui/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-col"
    ...attributes>
    {{yield (hash
    Item=(component "ui/data/item"))}}
  </div>
  */
  {"id":"Tlr4KMTc","block":"{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"flex flex-col\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"Item\"],[[30,[36,0],[\"ui/data/item\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/data.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});