define("fuel-portal/utils/cards/card-avatar-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cardAvatarPath;

  function cardAvatarPath(cardType) {
    switch (cardType) {
      case "BP LIST":
        return "/assets/images/cards/bplist.png";

      case "BP BUNKER":
        return "/assets/images/cards/bpbunker.png";

      case "BP PLUS":
        return "/assets/images/cards/bpplus.png";

      case "SHELL FLT":
        return "/assets/images/cards/shellflt.png";

      case "SHELLFLT2":
        return "/assets/images/cards/shellflt2.png";

      case "SHELL CRT":
        return "/assets/images/cards/shellcrt.png";

      case "SHELLMULTI":
        return "/assets/images/cards/shellmulti.png";

      case "SHELLPRIME":
        return "/assets/images/cards/shellprime.png";

      case "UK FUELS":
        return "/assets/images/cards/ukfuels.png";

      case "ESSO":
        return "/assets/images/cards/esso.png";

      case "ESSO TRUCK":
        return "/assets/images/cards/essotruck.png";

      case "FUELGENIE":
        return "/assets/images/cards/fuelgenie.png";

      case "D DIRECT":
        return "/assets/images/cards/ddirect.png";

      case "FASTFUELS":
        return "/assets/images/cards/fastfuels.png";

      default:
        // window.console.log(this.type);
        return "/assets/images/cards/default.png";
    }
  }
});