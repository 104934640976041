define("fuel-portal/components/ui/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="bg-white rounded-2xl flex flex-col"
    ...attributes>
  
    {{#if @title}}
  
      <header class="px-6 py-4 border-b border-gray-100 rounded-t-2xl shadow-bottom">
        <h1 class="text-lg font-bold leading-6">{{@title}}</h1>
      </header>
  
    {{/if}}
  
    {{yield}}
  
  </section>
  */
  {"id":"3ku6d8JA","block":"{\"symbols\":[\"@title\",\"&attrs\",\"&default\"],\"statements\":[[11,\"section\"],[24,0,\"bg-white rounded-2xl flex flex-col\"],[17,2],[12],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"header\"],[14,0,\"px-6 py-4 border-b border-gray-100 rounded-t-2xl shadow-bottom\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"text-lg font-bold leading-6\"],[12],[1,[32,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,3,null],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/card.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});