define("fuel-portal/utils/shape-params-accounts", ["exports", "fuel-portal/utils/shape-params"], function (_exports, _shapeParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapeParams;

  function shapeSearch(params) {
    if (!Ember.isEmpty(params.search)) {
      params.number = {};
      params.number.LIKE = '%' + params.search + '%';
      delete params.search;
    }
  } //Public functions


  function shapeParams(params) {
    return (0, _shapeParams.shapeReducer)(shapeSearch)(params);
  }
});