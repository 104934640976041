define("fuel-portal/components/ui/header/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-row justify-end items-center mb-2 md:mb-0 text-gray-800"
    data-test-header-actions>
    {{yield (hash
      Action=(component "ui/header/action")
      Search=(component "ui/header/search")
    )}}
  </div>
  */
  {"id":"amDXpPd7","block":"{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-row justify-end items-center mb-2 md:mb-0 text-gray-800\"],[14,\"data-test-header-actions\",\"\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"Action\",\"Search\"],[[30,[36,0],[\"ui/header/action\"],null],[30,[36,0],[\"ui/header/search\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/header/actions.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});