define("fuel-portal/components/ui/dashboard/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-col md:flex-row"
    ...attributes>
    <Ui::Data::Item @label="Company Name"
      @value={{@account.name}}
      class="w-full md:border-b-0" />
    <Ui::Data::Item @label="Account Contact"
      @value={{@account.contact}}
      class="w-full md:border-b-0" />
    <Ui::Data::Item @label="Account Email Address"
      @value={{@account.contactEmail}}
      class="w-full md:border-b-0" />
  </div>
  */
  {"id":"8/cYl2Ii","block":"{\"symbols\":[\"&attrs\",\"@account\"],\"statements\":[[11,\"div\"],[24,0,\"flex flex-col md:flex-row\"],[17,1],[12],[2,\"\\n  \"],[8,\"ui/data/item\",[[24,0,\"w-full md:border-b-0\"]],[[\"@label\",\"@value\"],[\"Company Name\",[32,2,[\"name\"]]]],null],[2,\"\\n  \"],[8,\"ui/data/item\",[[24,0,\"w-full md:border-b-0\"]],[[\"@label\",\"@value\"],[\"Account Contact\",[32,2,[\"contact\"]]]],null],[2,\"\\n  \"],[8,\"ui/data/item\",[[24,0,\"w-full md:border-b-0\"]],[[\"@label\",\"@value\"],[\"Account Email Address\",[32,2,[\"contactEmail\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"fuel-portal/components/ui/dashboard/customer.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});