define("fuel-portal/utils/get-api-url", ["exports", "fuel-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getApiUrl;
  _exports.getApiUrlWithoutNamespace = getApiUrlWithoutNamespace;

  function getApiUrl() {
    var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return path.indexOf('http') === 0 ? path : "".concat(_environment.default.rootApiURL, "/").concat(_environment.default.apiNamespace).concat(path);
  }

  function getApiUrlWithoutNamespace() {
    var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return path.indexOf('http') === 0 ? path : "".concat(_environment.default.rootApiURL).concat(path);
  }
});