define("fuel-portal/utils/sort-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortBy;

  function sortBy(controller, attribute) {
    var sort = controller.sort;

    if (sort === attribute) {
      attribute = "-".concat(sort);
    }

    controller.transitionToRoute({
      queryParams: {
        sort: attribute
      }
    });
    controller.set("page", 1);
  }
});