define("fuel-portal/components/ui/account/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <heading class="flex flex-row text-black justify-between py-4 border-t border-b border-gray-300 mb-3">
    <h1 class="font-bold text-base leading-5">Account Help</h1>
    {{svg-jar "question-mark-circle" class="w-5 h-5"}}
  </heading>
  
  <img src="/assets/images/help.accounts.jpg"
    alt="Desk meeting"
    class="w-full mb-3" />
  
  <div class="font-light text-sm leading-5 space-y-3">
  
    {{#if (eq @type "list")}}
      <p>Accounts are Organisations/Companies that use fuel cards. As an administrator you can view cards, transactions & invoices specific to an account from this page.</p>
  
      <p><strong class="font-bold">Select</strong> an account from the list to view futher details.</p>
  
      <div class="flex flex-row justify-start items-center">
        {{svg-jar "dots-vertical" class="w-4 h-4 mr-2"}}
        <p>Jump to a specific account screen.</p>
      </div>
      <div class="flex flex-row justify-start items-center">
        {{svg-jar "search" class="w-4 h-4 mr-2"}}
        <p>Search by company name to find an account quickly.</p>
      </div>
    {{/if}}
  
  </div>
  */
  {"id":"pyNJ57ni","block":"{\"symbols\":[\"@type\"],\"statements\":[[10,\"heading\"],[14,0,\"flex flex-row text-black justify-between py-4 border-t border-b border-gray-300 mb-3\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"font-bold text-base leading-5\"],[12],[2,\"Account Help\"],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"question-mark-circle\"],[[\"class\"],[\"w-5 h-5\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/help.accounts.jpg\"],[14,\"alt\",\"Desk meeting\"],[14,0,\"w-full mb-3\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"font-light text-sm leading-5 space-y-3\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,1],\"list\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"Accounts are Organisations/Companies that use fuel cards. As an administrator you can view cards, transactions & invoices specific to an account from this page.\"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"font-bold\"],[12],[2,\"Select\"],[13],[2,\" an account from the list to view futher details.\"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex flex-row justify-start items-center\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"dots-vertical\"],[[\"class\"],[\"w-4 h-4 mr-2\"]]]],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Jump to a specific account screen.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-row justify-start items-center\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"search\"],[[\"class\"],[\"w-4 h-4 mr-2\"]]]],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Search by company name to find an account quickly.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"eq\",\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/account/help.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});