define("fuel-portal/components/ui/header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{page-title @text}}
  <h1 class="text-2xl leading-8 font-serif font-bold"
    data-test-header-title={{dasherize @text}}>
    {{@text}}
  </h1>
  */
  {"id":"dC3UUNMB","block":"{\"symbols\":[\"@text\"],\"statements\":[[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"],[10,\"h1\"],[14,0,\"text-2xl leading-8 font-serif font-bold\"],[15,\"data-test-header-title\",[30,[36,1],[[32,1]],null]],[12],[2,\"\\n  \"],[1,[32,1]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"dasherize\"]}","meta":{"moduleName":"fuel-portal/components/ui/header/title.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});