define("fuel-portal/mirage/serializers/invoice", ["exports", "fuel-portal/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    searchByFields: Ember.A(['displayedAmount', 'displayedInvoiceId', 'displayedDateDue', 'displayedInvoiceDate'])
  });

  _exports.default = _default;
});