define("fuel-portal/utils/const/account", ["exports", "fuel-portal/utils/feature-enabled"], function (_exports, _featureEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ACCOUNT_DETAIL_ACTIONS = void 0;
  var ACCOUNT_DETAIL_ACTIONS = [{
    title: "Dashboard",
    route: "internal.account.dashboard"
  }, {
    title: "Journey Planner",
    route: "internal.account.journey"
  }, {
    title: "View Account",
    route: "internal.account.index"
  }, {
    title: "View Cards",
    route: "internal.account.cards"
  }, {
    title: "View Invoices",
    route: "internal.account.invoices"
  }, {
    title: "View Transactions",
    route: "internal.account.transactions"
  }];
  _exports.ACCOUNT_DETAIL_ACTIONS = ACCOUNT_DETAIL_ACTIONS;

  if ((0, _featureEnabled.default)("userManagement")) {
    ACCOUNT_DETAIL_ACTIONS.splice(1, 0, {
      title: "View Users",
      route: "internal.account.users"
    });
  }
});