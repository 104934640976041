define("fuel-portal/utils/const/invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INVOICE_EXPORT_PATH = _exports.INVOICE_DETAIL_ACTIONS = _exports.INVOICE_FILTER_ATTRS = _exports.INVOICE_SORT_OPTIONS = _exports.INVOICE_STATUS_OPTIONS = void 0;
  var INVOICE_STATUS_OPTIONS = ['Raised', 'Other'];
  _exports.INVOICE_STATUS_OPTIONS = INVOICE_STATUS_OPTIONS;
  var INVOICE_SORT_OPTIONS = [{
    label: 'Invoice Number',
    value: 'number'
  }, {
    label: 'Date Raised',
    value: 'date'
  }, {
    label: 'Date Due',
    value: 'dueDate'
  }, {
    label: 'Amount',
    value: 'grossValue'
  }];
  _exports.INVOICE_SORT_OPTIONS = INVOICE_SORT_OPTIONS;
  var INVOICE_FILTER_ATTRS = ['grossValue', 'number', 'date', 'dueDate'];
  _exports.INVOICE_FILTER_ATTRS = INVOICE_FILTER_ATTRS;
  var INVOICE_DETAIL_ACTIONS = [
  /*{
      title: 'View Invoice',
      route: 'internal.invoice.index'
    },*/
  {
    title: 'View Transactions',
    route: 'internal.invoice.transactions'
  }];
  _exports.INVOICE_DETAIL_ACTIONS = INVOICE_DETAIL_ACTIONS;
  var INVOICE_EXPORT_PATH = '/invoice';
  _exports.INVOICE_EXPORT_PATH = INVOICE_EXPORT_PATH;
});