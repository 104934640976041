define("fuel-portal/utils/const/date-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LONG_DATETIME_FORMAT = _exports.SHORT_DATETIME_FORMAT = _exports.LONG_DATE_FORMAT = _exports.SHORT_DATE_FORMAT = _exports.TIME_FORMAT = void 0;
  // ---
  // Moment.js datetime format strings
  // https://momentjs.com/docs/#/displaying/
  // ---
  var TIME_FORMAT = "HH:mm";
  _exports.TIME_FORMAT = TIME_FORMAT;
  var SHORT_DATE_FORMAT = "DD/MM/YY";
  _exports.SHORT_DATE_FORMAT = SHORT_DATE_FORMAT;
  var LONG_DATE_FORMAT = "dddd Do MMMM YYYY";
  _exports.LONG_DATE_FORMAT = LONG_DATE_FORMAT;
  var SHORT_DATETIME_FORMAT = "".concat(SHORT_DATE_FORMAT, " ").concat(TIME_FORMAT);
  _exports.SHORT_DATETIME_FORMAT = SHORT_DATETIME_FORMAT;
  var LONG_DATETIME_FORMAT = "".concat(LONG_DATE_FORMAT, " ").concat(TIME_FORMAT);
  _exports.LONG_DATETIME_FORMAT = LONG_DATETIME_FORMAT;
});