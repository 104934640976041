define("fuel-portal/utils/normalise-card-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normaliseCardNumber;

  function normaliseCardNumber(cardNumber) {
    // Sometimes the BS API will compress a card number to
    // and expression so we get "7.00206E+18" instead of
    // 7002060000000000000
    // The function ensures that any expressions are normalised
    if (Ember.isEmpty(cardNumber)) {
      return "";
    } // check number is not an expression


    if (cardNumber.includes('E+') || cardNumber.includes('e+')) {
      // Convert the expression string into a number
      cardNumber = Number.parseFloat(cardNumber); // Convert it back to a string without the expression

      cardNumber = cardNumber.toPrecision();
    } // make sure it's a string


    return cardNumber;
  }
});