define("fuel-portal/components/ui/breadcrumbs/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mr-1 flex flex-row items-center overflow-hidden {{if @isLast "flex-none" ""}}">
    <div class="truncate text-xs">
      {{yield}}
    </div>
    {{#unless @isLast}}
      {{svg-jar "chevron-right" class="ml-1 sm:ml-2 w-4 h-4"}}
    {{/unless}}
  </div>
  */
  {"id":"ydI5zCB1","block":"{\"symbols\":[\"@isLast\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"mr-1 flex flex-row items-center overflow-hidden \",[30,[36,1],[[32,1],\"flex-none\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"truncate text-xs\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"chevron-right\"],[[\"class\"],[\"ml-1 sm:ml-2 w-4 h-4\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\",\"unless\"]}","meta":{"moduleName":"fuel-portal/components/ui/breadcrumbs/item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});