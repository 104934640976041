define("fuel-portal/mirage/helpers/responses", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorResponse = errorResponse;
  _exports.successResponse = successResponse;

  function errorResponse(errors) {
    return new _emberCliMirage.default.Response(422, {
      'Content-Type': 'application/vnd.api+json'
    }, {
      errors: errors
    });
  }

  function successResponse(status, data) {
    return new _emberCliMirage.default.Response(status, {
      'Content-Type': 'application/vnd.api+json'
    }, data);
  }
});