define("fuel-portal/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sM1HlrJq",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"title\"],[12],[1,[32,1,[\"title\"]]],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "fuel-portal/templates/head.hbs"
    }
  });

  _exports.default = _default;
});