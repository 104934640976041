define("fuel-portal/components/ui/account/list/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @loading}}
  
    <div class="flex space-x-6 items-center p-4">
      <div class="text-base leading-6 flex-grow max-w-xs pl-2">
        <div class="bg-gray-100 h-4 my-1 w-1/2 rounded-sm"></div>
        <div class="bg-gray-100 h-4 my-2 w-2/3 rounded-sm"></div>
        <div class="bg-gray-100 h-4 my-1 w-2/3 rounded-sm"></div>
      </div>
      <div class="flex-auto">
        <div class="ml-auto rounded-full bg-gray-200 w-5 h-5"></div>
      </div>
    </div>
  
  {{else}}
    <div class="flex space-x-6 items-center p-4">
  
      <div class="text-base leading-6 pl-2">
        <div class="font-bold">{{@account.name}}</div>
        <div class="font-light">Number: {{@account.number}}</div>
      </div>
  
      <div class="flex-1"></div>
  
      <div>
        <Ui::Dropdown as
          |dropdown|>
          <dropdown.Icon @icon="dots-vertical"
            {{on "click" dropdown.toggle}} />
          {{yield (hash
            Menu=dropdown.Menu
          )}}
        </Ui::Dropdown>
      </div>
    </div>
  
  {{/if}}
  */
  {"id":"gnKW+ul3","block":"{\"symbols\":[\"dropdown\",\"@account\",\"&default\",\"@loading\"],\"statements\":[[6,[37,2],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex space-x-6 items-center p-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-base leading-6 flex-grow max-w-xs pl-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"bg-gray-100 h-4 my-1 w-1/2 rounded-sm\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"bg-gray-100 h-4 my-2 w-2/3 rounded-sm\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"bg-gray-100 h-4 my-1 w-2/3 rounded-sm\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-auto\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ml-auto rounded-full bg-gray-200 w-5 h-5\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"flex space-x-6 items-center p-4\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"text-base leading-6 pl-2\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-bold\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-light\"],[12],[2,\"Number: \"],[1,[32,2,[\"number\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex-1\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"ui/dropdown\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"Icon\"]],[[4,[38,0],[\"click\",[32,1,[\"toggle\"]]],null]],[[\"@icon\"],[\"dots-vertical\"]],null],[2,\"\\n        \"],[18,3,[[30,[36,1],null,[[\"Menu\"],[[32,1,[\"Menu\"]]]]]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"hash\",\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/account/list/item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});