define("fuel-portal/mirage/factories/invoice", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    grossValue: function grossValue() {
      return _faker.default.random.number({
        min: 1,
        max: 8001
      });
    },
    netValue: function netValue() {
      return _faker.default.random.number({
        min: 1,
        max: 8001
      });
    },
    number: function number() {
      return generateSerial(6);
    },
    dateRaised: function dateRaised() {
      return _faker.default.date.recent();
    },
    dateDue: function dateDue() {
      return _faker.default.date.future(0.1);
    },
    status: function status() {
      return _faker.default.random.arrayElement(["pending", "paid", "overdue"]);
    },
    withAccount: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(invoice, server) {
        var account = _faker.default.random.arrayElement(server.schema.accounts.all().models);

        if (account) {
          invoice.update({
            account: account
          });
        }
      }
    })
  });
  /**
   * method to generate serial number (not unique)
   * parameter: number of digits
   *
   * @method generateSerial
   * @param {Number} number
   *
   */


  _exports.default = _default;

  function generateSerial(digits) {
    var wholeString = "";

    for (var i = 0; i < digits; i++) {
      wholeString += _faker.default.random.arrayElement(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]);
    }

    return wholeString;
  }
});