define("fuel-portal/mirage/fixtures/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var results = [];

  for (var i = 0; i < 1000; i++) {
    results.push({
      name: "Transaction ".concat(i)
    });
  }

  var _default = results;
  _exports.default = _default;
});