define("fuel-portal/mirage/scenarios/default", ["exports", "fuel-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    if (_environment.default.environment === "test") {
      return;
    }

    server.createList('account', 2, 'withVolumeByWeeks', 'withCardTypeUsages', 'withAccountBalance');
    server.loadFixtures('users');
    server.createList('user', 26, 'withAccount');
    server.createList('card', 26, 'withAccount');
    server.createList('invoice', 5, 'withAccount');
    server.createList('transaction', 50, 'withCard', 'withInvoice');
  }
});