define("fuel-portal/utils/date-utilities", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uiDateToActualDate = uiDateToActualDate;

  function uiDateToActualDate(uiDate) {
    var range = {
      from: null,
      to: null
    };

    if (uiDate.includes('~')) {
      range.from = (0, _moment.default)(uiDate.split('~')[0]).startOf('day');
      range.to = (0, _moment.default)(uiDate.split('~')[1]).endOf('day');
    } else {
      return null;
    }

    return {
      from: range.from.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      to: range.to.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    };
  }
});