define("fuel-portal/components/ui/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="p-6 pt-2 text-base font-light leading-5 text-center w-full">
  
    <p class="mb-10">{{svg-jar "phone" class="w-4 h-4 inline-flex mr-2"}} Have a question? Call us: <a href="tel:+448001583582">08001583582</a> or email <a href="mailto:enquiries@fuelmate.co.uk">enquiries@fuelmate.co.uk</a></p>
  
  </footer>
  */
  {"id":"DrnLhr/F","block":"{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"p-6 pt-2 text-base font-light leading-5 text-center w-full\"],[12],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"mb-10\"],[12],[1,[30,[36,0],[\"phone\"],[[\"class\"],[\"w-4 h-4 inline-flex mr-2\"]]]],[2,\" Have a question? Call us: \"],[10,\"a\"],[14,6,\"tel:+448001583582\"],[12],[2,\"08001583582\"],[13],[2,\" or email \"],[10,\"a\"],[14,6,\"mailto:enquiries@fuelmate.co.uk\"],[12],[2,\"enquiries@fuelmate.co.uk\"],[13],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"fuel-portal/components/ui/footer.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});