define("fuel-portal/utils/get-page-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPageParams;

  function getPageParams(params) {
    var page = {
      number: params.page,
      size: params.size
    };
    delete params.page;
    delete params.size;
    return page;
  }
});