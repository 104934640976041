define("fuel-portal/templates/internal/account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFTrgm/n",
    "block": "{\"symbols\":[\"header\"],\"statements\":[[2,\"  \"],[8,\"ui/account/crumbs\",[],[[\"@page\",\"@account\",\"@loading\"],[\"View\",[32,0,[\"account\"]],[32,0,[\"loading\"]]]],null],[2,\"\\n\\n  \"],[8,\"ui/header\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Title\"]],[],[[\"@text\"],[\"View Account\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"section\"],[14,0,\"px-6 lg:px-18 flex flex-col space-y-4 mb-4 flex-1\"],[12],[2,\"\\n    \"],[8,\"ui/card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui/action-buttons\",[],[[\"@actions\",\"@model\"],[[32,0,[\"accountActions\"]],[32,0,[\"account\",\"id\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"ui/card\",[],[[\"@title\"],[\"About this Account\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui/account/data\",[],[[\"@account\",\"@loading\"],[[32,0,[\"account\"]],[32,0,[\"loading\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "fuel-portal/templates/internal/account/index.hbs"
    }
  });

  _exports.default = _default;
});