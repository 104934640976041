define("fuel-portal/helpers/format-short-date", ["exports", "fuel-portal/utils/const/date-formats", "moment"], function (_exports, _dateFormats, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatShortDate(params
  /*, hash*/
  ) {
    var date = params[0],
        emptyString = params[1] ? params[1] : "n/a";

    if (date) {
      return (0, _moment.default)(date).format(_dateFormats.SHORT_DATE_FORMAT);
    }

    return emptyString;
  });

  _exports.default = _default;
});