define("fuel-portal/components/ui/transaction/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="px-4">
    <Ui::Data as
      |data|>
      <data.Item @loading={{@loading}}
        @label="Vehicle/Person"
        @value={{@transaction.card.name}}
        @tip="The name/vehicle on the card" />
      <data.Item @loading={{@loading}}
        @label="Card Number"
        @value={{@transaction.card.formattedNumber}}
        @tip="Card number used in this transaction" />
      <data.Item @loading={{@loading}}
        @label="Card Type"
        @value={{@transaction.card.type}}
        @tip="Type of card" />
      <data.Item @loading={{@loading}}
        @label="Date"
        @value={{format-long-date @transaction.date}}
        @tip="The date of this transaction" />
      <data.Item @loading={{@loading}}
        @label="Time"
        @value={{format-time @transaction.date}}
        @tip="The time of this transaction" />
      <data.Item @loading={{@loading}}
        @label="Product"
        @value={{@transaction.productName}}
        @tip="The type of product purchased" />
      <data.Item @loading={{@loading}}
        @label="Amount"
        @value={{transaction-amount-formatter @transaction}}
        @tip="The volume of fuel purchased" />
    </Ui::Data>
  </div>
  */
  {"id":"uGwRh+gT","block":"{\"symbols\":[\"data\",\"@loading\",\"@transaction\"],\"statements\":[[10,\"div\"],[14,0,\"px-4\"],[12],[2,\"\\n  \"],[8,\"ui/data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Vehicle/Person\",[32,3,[\"card\",\"name\"]],\"The name/vehicle on the card\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Card Number\",[32,3,[\"card\",\"formattedNumber\"]],\"Card number used in this transaction\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Card Type\",[32,3,[\"card\",\"type\"]],\"Type of card\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Date\",[30,[36,0],[[32,3,[\"date\"]]],null],\"The date of this transaction\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Time\",[30,[36,1],[[32,3,[\"date\"]]],null],\"The time of this transaction\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Product\",[32,3,[\"productName\"]],\"The type of product purchased\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Amount\",[30,[36,2],[[32,3]],null],\"The volume of fuel purchased\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format-long-date\",\"format-time\",\"transaction-amount-formatter\"]}","meta":{"moduleName":"fuel-portal/components/ui/transaction/data.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});