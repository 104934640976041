define("fuel-portal/components/card/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Properties
    base: 'card',
    // Computed
    loaded: Ember.computed.not('loading'),
    componentPath: Ember.computed.alias('card.cardPreviewPath')
  });

  _exports.default = _default;
});