define("fuel-portal/helpers/sort-menu-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function sortMenuIcon(params
  /*, hash*/
  ) {
    var sortValue = params[0],
        optionValue = params[1];

    if (sortValue === optionValue) {
      return 'arrow-narrow-up';
    } else if (sortValue === "-".concat(optionValue)) {
      return 'arrow-narrow-down';
    } else {
      return '';
    }
  });

  _exports.default = _default;
});