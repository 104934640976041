define("fuel-portal/helpers/format-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatString(params
  /*, hash*/
  ) {
    var string = params[0],
        empty = params[1] ? params[1] : "";

    if (Ember.isEmpty(string)) {
      return empty;
    }

    return string;
  });

  _exports.default = _default;
});