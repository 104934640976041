define("fuel-portal/templates/internal/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4mllBQE",
    "block": "{\"symbols\":[\"crumb\"],\"statements\":[[8,\"ui/breadcrumbs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"Item\"]],[],[[\"@isLast\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    Logout\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"my-6 px-6 lg:px-18 flex flex-col space-y-4 mb-4 flex-1\"],[12],[2,\"\\n  \"],[8,\"ui/card\",[[24,0,\"flex-auto flex flex-col justify-center items-center p-6\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"text-lg font-light\"],[12],[2,\"\\n      Logging out, goodbye...\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "fuel-portal/templates/internal/logout.hbs"
    }
  });

  _exports.default = _default;
});