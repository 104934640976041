define("fuel-portal/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatDate(params
  /*, hash*/
  ) {
    window.console.warn("formatDate helper is deprecated - use one of the specific date format helpers instead");
    return (0, _moment.default)(params[0]).format(params[1]);
  });

  _exports.default = _default;
});