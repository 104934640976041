define("fuel-portal/components/ui/dropdown/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button role="button"
    type="button"
    class="w-6 h-6 rounded-full pointer-cursor hover:bg-gray-100 focus:outline-none focus:shadow-outline-primary flex justify-center items-center"
    ...attributes>
    {{svg-jar @icon class="w-5 h-5"}}
  </button>
  */
  {"id":"EndWolVC","block":"{\"symbols\":[\"&attrs\",\"@icon\"],\"statements\":[[11,\"button\"],[24,\"role\",\"button\"],[24,0,\"w-6 h-6 rounded-full pointer-cursor hover:bg-gray-100 focus:outline-none focus:shadow-outline-primary flex justify-center items-center\"],[17,1],[24,4,\"button\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,2]],[[\"class\"],[\"w-5 h-5\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"fuel-portal/components/ui/dropdown/icon.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});