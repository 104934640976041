define("fuel-portal/mirage/factories/thing", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    title: function title() {
      return _faker.default.lorem.words(3);
    },
    content: function content() {
      return _faker.default.lorem.paragraphs(2);
    },
    date: function date() {
      return _faker.default.date.recent();
    },
    withUser: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(thing, server) {
        var user = _faker.default.random.arrayElement(server.schema.users.all().models);

        if (user) {
          thing.update({
            user: user
          });
        }
      }
    })
  });

  _exports.default = _default;
});