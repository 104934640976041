define("fuel-portal/mirage/factories/card", ["exports", "ember-cli-mirage", "faker", "fuel-portal/utils/const/card"], function (_exports, _emberCliMirage, _faker, _card) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    driver: function driver() {
      if (_faker.default.random.boolean()) {
        return _faker.default.name.findName() + _faker.default.name.lastName();
      } else {
        return null;
      }
    },
    vrn: function vrn() {
      // UK Reg
      // [two letters][two digits][3 random letters]
      if (!this.driver) {
        return alpha({
          count: 2,
          upcase: true
        }) + _faker.default.random.number({
          min: 10,
          max: 99
        }) + " " + alpha({
          count: 3,
          upcase: true
        });
      } else {
        return null;
      }
    },
    number: function number() {
      // make sure returned value is a string
      if (_faker.default.random.boolean()) {
        return "7.00206E+18";
      } else {
        // 7082 2486 9235 0099 21X
        // 7002 0600 0000 0000 000
        return _faker.default.random.number({
          min: 1000000000000000000,
          max: 9999999999999999999
        }) + "";
      } // return (
      //   "**************" +
      //   faker.random.number({
      //     min: 10000,
      //     max: 99999
      //   })
      // );

    },
    status: function status() {
      return _faker.default.random.arrayElement(_card.CARD_STATUS_OPTIONS);
    },
    expiry: function expiry() {
      return _faker.default.date.future(3, '2018-10-10');
    },
    type: function type() {
      return _faker.default.random.arrayElement(_card.CARD_TYPE_OPTIONS).toUpperCase();
    },
    allowedProducts: function allowedProducts() {
      return _faker.default.random.arrayElement(_card.CARD_TYPE_OPTIONS[this.type]);
    },
    dateLastUsed: function dateLastUsed() {
      return _faker.default.date.recent();
    },
    locationLastUsed: function locationLastUsed() {
      return _faker.default.company.companyName();
    },
    costCentre: function costCentre() {
      return _faker.default.random.arrayElement([null, 'Cost Center A', 'Cost Center B', 'Cost Center C']);
    },
    canRequestPIN: function canRequestPIN() {
      return _faker.default.random.boolean();
    },
    withAccount: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(card, server) {
        var account = _faker.default.random.arrayElement(server.schema.accounts.all().models);

        if (account) {
          card.update({
            account: account
          });
        }
      }
    })
  });
  /**
   * alpha. returns lower/upper alpha characters based count and upcase options
   *
   * @method faker.random.alpha
   * @param {mixed} options // defaults to { count: 1, upcase: false }
   */


  _exports.default = _default;

  function alpha(options) {
    if (typeof options === "undefined") {
      options = {
        count: 1
      };
    } else if (typeof options === "number") {
      options = {
        count: options
      };
    } else if (typeof options.count === "undefined") {
      options.count = 1;
    }

    if (typeof options.upcase === "undefined") {
      options.upcase = false;
    }

    var wholeString = "";

    for (var i = 0; i < options.count; i++) {
      wholeString += _faker.default.random.arrayElement(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    }

    return options.upcase ? wholeString.toUpperCase() : wholeString;
  }
});