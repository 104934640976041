define("fuel-portal/mirage/fixtures/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'mike.bower@wearesauce.io',
    Id: 'mike.bower@wearesauce.io',
    firstName: 'Mike',
    lastName: 'Bower',
    email: 'mike.bower@wearesauce.io',
    role: 'Admin',
    accountIds: ['1']
  } // {
  //   Id: 'alexandra.docolin@wearesauce.io',
  //   firstName: 'Alexandra',
  //   lastName: 'Docolin',
  //   email: 'alexandra.docolin@wearesauce.io',
  //   role: 'Admin'
  // },
  // {
  //   Id: 'mark.hickson@wearesauce.io',
  //   firstName: 'Mark',
  //   lastName: 'Hickson',
  //   email: 'mark.hickson@wearesauce.io',
  //   role: 'Admin'
  // },
  // {
  //   Id: 'danny.wilkinson@wearesauce.io',
  //   firstName: 'Danny',
  //   lastName: 'Wilkinson',
  //   email: 'danny.wilkinson@wearesauce.io',
  //   role: 'Admin'
  // },
  // {
  //   Id: 'jim@wearesauce.io',
  //   firstName: 'Jim',
  //   lastName: 'Wardlaw',
  //   email: 'jim@wearesauce.io',
  //   role: 'Admin'
  // },
  // {
  //   Id: 'adam.clarkson+admin@wearesauce.io',
  //   firstName: 'Adam',
  //   lastName: 'Clarkson',
  //   email: 'adam.clarkson+admin@wearesauce.io',
  //   role: 'Admin'
  // },
  // {
  //   Id: 'adam.clarkson+carduser@wearesauce.io',
  //   firstName: 'Adam',
  //   lastName: 'Clarkson',
  //   email: 'adam.clarkson+carduser@wearesauce.io',
  //   role: 'user',
  //   accountId: '1'
  // },
  // {
  //   Id: 'adam.clarkson+testauto1@wearesauce.io',
  //   firstName: 'Adam',
  //   lastName: 'Clarkson',
  //   email: 'adam.clarkson+testauto1@wearesauce.io',
  //   role: 'Admin'
  // }
  ];
  _exports.default = _default;
});