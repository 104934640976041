define("fuel-portal/utils/const/transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRANSACTION_EXPORT_PATH = _exports.TRANSACTION_DETAIL_OPTIONS = _exports.TRANSACTION_FILTER_ATTRS = _exports.TRANSACTION_SORT_OPTIONS = void 0;
  var TRANSACTION_SORT_OPTIONS = [{
    label: 'Date',
    value: 'date'
  }, {
    label: 'Location',
    value: 'locationName'
  }, {
    label: 'Volume',
    value: 'quantity'
  }];
  _exports.TRANSACTION_SORT_OPTIONS = TRANSACTION_SORT_OPTIONS;
  var TRANSACTION_FILTER_ATTRS = ['range'];
  _exports.TRANSACTION_FILTER_ATTRS = TRANSACTION_FILTER_ATTRS;
  var TRANSACTION_DETAIL_OPTIONS = [{
    title: 'View Transaction',
    route: 'internal.transaction.index'
  }];
  _exports.TRANSACTION_DETAIL_OPTIONS = TRANSACTION_DETAIL_OPTIONS;
  var TRANSACTION_EXPORT_PATH = '/transactions';
  _exports.TRANSACTION_EXPORT_PATH = TRANSACTION_EXPORT_PATH;
});