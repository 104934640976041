define("fuel-portal/components/ui/account/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="px-4">
    <Ui::Data as
      |data|>
  
      <data.Item @loading={{@loading}}
        @label="Name"
        @value={{@account.name}}
        @tip="Your full company name" />
      <data.Item @loading={{@loading}}
        @label="Address"
        @value={{@account.addressComputed}} />
      <data.Item @loading={{@loading}}
        @label="Postal Code"
        @value={{@account.postCode}} />
      <data.Item @loading={{@loading}}
        @label="Number"
        @value={{@account.number}}
        @tip="The account identification number" />
      <data.Item @loading={{@loading}}
        @label="Credit Limit"
        @value={{@account.cardLimit}}
        @tip="The maximum spend limit (£) on an account" />
      <data.Item @loading={{@loading}}
        @label="Payment Terms"
        @value={{@account.terms}}
        @tip="The period of time that it takes us to call for a direct debit" />
      <data.Item @loading={{@loading}}
        @label="Contact"
        @value={{@account.contact}} />
      <data.Item @loading={{@loading}}
        @label="Contact Telephone"
        @value={{@account.contactTelephone}} />
      <data.Item @loading={{@loading}}
        @label="Contact E-mail"
        @value={{@account.contactEmail}} />
  
    </Ui::Data>
  </div>
  */
  {"id":"7YL4wnNz","block":"{\"symbols\":[\"data\",\"@loading\",\"@account\"],\"statements\":[[10,\"div\"],[14,0,\"px-4\"],[12],[2,\"\\n  \"],[8,\"ui/data\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Name\",[32,3,[\"name\"]],\"Your full company name\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\"],[[32,2],\"Address\",[32,3,[\"addressComputed\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\"],[[32,2],\"Postal Code\",[32,3,[\"postCode\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Number\",[32,3,[\"number\"]],\"The account identification number\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Credit Limit\",[32,3,[\"cardLimit\"]],\"The maximum spend limit (£) on an account\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\",\"@tip\"],[[32,2],\"Payment Terms\",[32,3,[\"terms\"]],\"The period of time that it takes us to call for a direct debit\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\"],[[32,2],\"Contact\",[32,3,[\"contact\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\"],[[32,2],\"Contact Telephone\",[32,3,[\"contactTelephone\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[\"@loading\",\"@label\",\"@value\"],[[32,2],\"Contact E-mail\",[32,3,[\"contactEmail\"]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"fuel-portal/components/ui/account/data.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});