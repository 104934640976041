define("fuel-portal/mirage/factories/volumebyweek", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    quantity: function quantity() {
      return _emberCliMirage.faker.random.number({
        min: 1,
        max: 200
      });
    },
    weekBegin: function weekBegin() {
      return _emberCliMirage.faker.date.recent();
    }
  });

  _exports.default = _default;
});