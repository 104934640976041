define("fuel-portal/components/ui/form/multi-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mt-1"
    aria-describedby={{@aria-describedby}}
    data-test-dropdown={{@name}}>
    <PowerSelectMultiple @selected={{@selected}}
      @options={{@options}}
      @onChange={{@onChange}}
      @searchField={{@searchField}}
      @placeholder={{@placeholder}}
      @dropdownClass={{concat "ember-power-select-dropdown-" @name}}
      as
      |option|>
      {{yield option}}
    </PowerSelectMultiple>
  </div>
  */
  {"id":"+IaTx6yr","block":"{\"symbols\":[\"option\",\"@aria-describedby\",\"@name\",\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"mt-1\"],[15,\"aria-describedby\",[32,2]],[15,\"data-test-dropdown\",[32,3]],[12],[2,\"\\n  \"],[8,\"power-select-multiple\",[],[[\"@selected\",\"@options\",\"@onChange\",\"@searchField\",\"@placeholder\",\"@dropdownClass\"],[[32,4],[32,5],[32,6],[32,7],[32,8],[30,[36,0],[\"ember-power-select-dropdown-\",[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,9,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}","meta":{"moduleName":"fuel-portal/components/ui/form/multi-dropdown.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});