define("fuel-portal/helpers/maybe-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function maybeDate(params
  /*, hash*/
  ) {
    if (params[0] === 'Invalid date') {
      return params[1];
    } else {
      return params[0];
    }
  });

  _exports.default = _default;
});