define("fuel-portal/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    Id: function Id() {
      return _faker.default.internet.email();
    },
    firstName: function firstName() {
      return _faker.default.name.firstName();
    },
    lastName: function lastName() {
      return _faker.default.name.lastName();
    },
    // email() {
    //   return this.id;
    // },
    role: function role() {
      return _faker.default.random.arrayElement(['Admin', 'User']);
    },
    withAccount: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        // do not link admins to an account
        if (user.role === 'Admin') {
          return;
        }

        var account = _faker.default.random.arrayElement(server.schema.accounts.all().models);

        if (account) {
          user.update({
            accounts: [account]
          });
        }
      }
    })
  });

  _exports.default = _default;
});