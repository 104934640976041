define("fuel-portal/components/ui/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="py-6 px-6 lg:px-20 flex flex-col-reverse md:flex-row justify-between">
    {{yield (hash
      Title=(component "ui/header/title")
      Actions=(component "ui/header/actions")
    )}}
  </header>
  */
  {"id":"xa/rNYCJ","block":"{\"symbols\":[\"&default\"],\"statements\":[[10,\"header\"],[14,0,\"py-6 px-6 lg:px-20 flex flex-col-reverse md:flex-row justify-between\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"Title\",\"Actions\"],[[30,[36,0],[\"ui/header/title\"],null],[30,[36,0],[\"ui/header/actions\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/header.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});