define("fuel-portal/components/ui/side-nav/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar @icon class="absolute inset-y-0 left-0 m-2 h-6 w-6 text-green-500 group-hover:text-green-600 group-focus:text-green-600 transition ease-in-out duration-150"}}
  */
  {"id":"yYgnXCdc","block":"{\"symbols\":[\"@icon\"],\"statements\":[[1,[30,[36,0],[[32,1]],[[\"class\"],[\"absolute inset-y-0 left-0 m-2 h-6 w-6 text-green-500 group-hover:text-green-600 group-focus:text-green-600 transition ease-in-out duration-150\"]]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}","meta":{"moduleName":"fuel-portal/components/ui/side-nav/icon.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});