define("fuel-portal/utils/const/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_ROLE_OPTIONS = _exports.USER_SORT_OPTIONS = _exports.USER_DETAIL_ACTIONS = void 0;
  var USER_DETAIL_ACTIONS = [{
    title: "View User",
    route: "internal.user.index"
  }, {
    title: "Edit User",
    route: "internal.user.edit"
  }, {
    title: "Archive User",
    route: "internal.user.archive"
  }];
  _exports.USER_DETAIL_ACTIONS = USER_DETAIL_ACTIONS;
  var USER_SORT_OPTIONS = [// {
  //   label: "Email",
  //   value: "id"
  // },
  {
    label: "First Name",
    value: "firstName"
  }, {
    label: "Last Name",
    value: "lastName"
  }, {
    label: "Role",
    value: "role"
  }];
  _exports.USER_SORT_OPTIONS = USER_SORT_OPTIONS;
  var USER_ROLE_OPTIONS = ["Admin", "User"];
  _exports.USER_ROLE_OPTIONS = USER_ROLE_OPTIONS;
});