define("fuel-portal/utils/cards/save-card", ["exports", "jquery", "fuel-portal/utils/get-api-url"], function (_exports, _jquery, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = saveCard;

  function saveCard(cardType, accountId, details) {
    var postData = JSON.stringify({
      accountId: accountId,
      cardType: cardType,
      details: details
    });
    var url = (0, _getApiUrl.getApiUrlWithoutNamespace)('/restapi/v1/cards/ordernew');
    return new Ember.RSVP.Promise(function (resolve, reject) {
      _jquery.default.post(url, postData).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
});