define("fuel-portal/mirage/factories/account", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },
    creationDate: function creationDate() {
      return _emberCliMirage.faker.date.between("2015-08-01", "2019-08-20");
    },
    address: function address() {
      return _emberCliMirage.faker.address.streetAddress();
    },
    country: function country() {
      return _emberCliMirage.faker.address.country();
    },
    postCode: function postCode() {
      return _emberCliMirage.faker.address.zipCode();
    },
    number: function number() {
      return _emberCliMirage.faker.phone.phoneNumber();
    },
    lastUsed: function lastUsed() {
      return new Date();
    },
    terms: function terms() {
      var day = _emberCliMirage.faker.random.number({
        min: 1,
        max: 30
      });

      return "are INVOICE + " + day + " DAYS";
    },
    contact: function contact() {
      return _emberCliMirage.faker.name.firstName() + " " + _emberCliMirage.faker.name.lastName();
    },
    contactEmail: function contactEmail() {
      return _emberCliMirage.faker.internet.email();
    },
    cardTotal: function cardTotal() {
      return _emberCliMirage.faker.random.number({
        min: 50,
        max: 100
      });
    },
    cardsInUse: function cardsInUse() {
      return _emberCliMirage.faker.random.number({
        min: 1,
        max: 50
      });
    },
    cardLimit: function cardLimit() {
      return _emberCliMirage.faker.random.number({
        min: 1000,
        max: 100000
      });
    },
    weeksVolume: function weeksVolume() {
      return _emberCliMirage.faker.random.number({
        min: 1000,
        max: 5000
      });
    },
    potentialVolume: function potentialVolume() {
      return _emberCliMirage.faker.random.number({
        min: 5000,
        max: 6000
      });
    },
    accountManager: function accountManager() {
      return _emberCliMirage.faker.name.firstName() + " " + _emberCliMirage.faker.name.lastName();
    },
    lat: function lat() {
      // uk bounds (min/max)
      return _emberCliMirage.faker.random.number({
        min: 50747318,
        max: 53784832
      }) / 1000000;
    },
    lng: function lng() {
      // uk bounds (min/max)
      return _emberCliMirage.faker.random.number({
        min: -2902081,
        max: -178663
      }) / 1000000;
    },
    withVolumeByWeeks: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(account, server) {
        var date = (0, _moment.default)().startOf('week');

        for (var i = 0; i < 5; i++) {
          var weekBegin = date.subtract(i, 'week').format();
          server.create('volumebyweek', {
            weekBegin: weekBegin,
            account: account
          });
        }
      }
    }),
    withCardTypeUsages: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(account, server) {
        server.createList('cardtypeusage', _emberCliMirage.faker.random.number({
          min: 2,
          max: 8
        }), {
          account: account
        });
      }
    }),
    withAccountBalance: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(account, server) {
        server.create('accountbalance', {
          account: account
        });
      }
    })
  });

  _exports.default = _default;
});