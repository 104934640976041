define("fuel-portal/components/ui/dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!--
    Dropdown panel, show/hide based on dropdown state.
  
    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  --}}
  
  <div class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg transition ease-out duration-100 {{if @showMenu "transform opacity-100 scale-10 pointer-events-auto" "transform opacity-0 scale-95 pointer-events-none"}}">
    <div class="rounded-md bg-white shadow-xs">
      <div class="py-1"
        role="button"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        {{on "click" @toggleMenu}}>
        {{yield (hash Item=(component "ui/dropdown/menu/item" ))}}
      </div>
    </div>
  </div>
  */
  {"id":"XJGOOtL+","block":"{\"symbols\":[\"@showMenu\",\"@toggleMenu\",\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg transition ease-out duration-100 \",[30,[36,0],[[32,1],\"transform opacity-100 scale-10 pointer-events-auto\",\"transform opacity-0 scale-95 pointer-events-none\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"rounded-md bg-white shadow-xs\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"py-1\"],[24,\"role\",\"button\"],[24,\"aria-orientation\",\"vertical\"],[24,\"aria-labelledby\",\"options-menu\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n      \"],[18,3,[[30,[36,3],null,[[\"Item\"],[[30,[36,2],[\"ui/dropdown/menu/item\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/dropdown/menu.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});