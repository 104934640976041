define("fuel-portal/initializers/json-api", ["exports", "jquery", "fuel-portal/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    /**
     * This function adds the auth token and some headers
     * to any json request made by jquery
     */
    _jquery.default.ajaxSetup({
      timeout: 1000 * 60
    });

    _jquery.default.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      // window.console.log("REQUEST: " + options.url);
      if (options.url.startsWith(_environment.default.analytics.apiUrl)) {
        // do not add header info to analytics requests
        return;
      }

      options.url = decodeURIComponent(options.url);
      options.contentType = 'application/vnd.api+json';

      options.beforeSend = function (jqXHR) {
        jqXHR.setRequestHeader('Accept', 'application/vnd.api+json');
        jqXHR.setRequestHeader('Content-Type', 'application/vnd.api+json');
      };

      if (localStorage.getItem('auth_token')) {
        jqXHR.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth_token'));
      }

      if (options.dataType == 'csv') {
        options.contentType = 'text/csv';

        options.beforeSend = function (jqXHR) {
          jqXHR.setRequestHeader('Accept', 'text/csv');
          jqXHR.setRequestHeader('Content-Type', 'text/csv');
        };
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});