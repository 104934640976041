define("fuel-portal/utils/const/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ShapeLocations = _exports.ShapeCards = _exports.CREDIT_LIMIT = _exports.DASHBOARD_LOCATIONS = _exports.CHART_COLORS = _exports.DASHBOARD_DETAIL_ACTIONS = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DASHBOARD_DETAIL_ACTIONS = [{
    title: "Add New Card",
    route: "internal.cards.new"
  } // {
  //   title: "Add New User",
  //   route: "internal.users.new"
  // }
  ];
  _exports.DASHBOARD_DETAIL_ACTIONS = DASHBOARD_DETAIL_ACTIONS;
  var colorNameToHex = {
    'warning': '#f0cf28',
    'primary': '#ff5f00',
    'accent': '#2b3897',
    'success': '#61b345'
  };
  var CHART_COLORS = [['#68DAAE',
  /*'#23B67D',*/
  '#27CA8B'], ['#4F8DA8',
  /*'#045375',*/
  '#045C82']];
  _exports.CHART_COLORS = CHART_COLORS;
  var DASHBOARD_LOCATIONS = [{
    color: colorNameToHex['warning'],
    colorName: 'warning',
    value: 34,
    name: "UK Fuel Bunker Band D"
  }, {
    color: colorNameToHex['primary'],
    colorName: 'primary',
    value: 5,
    name: "UK Fuel Bunker Band B"
  }, {
    color: colorNameToHex['accent'],
    colorName: 'accent',
    value: 50,
    name: "UK Fuel Bunker Band E"
  }, {
    color: colorNameToHex['success'],
    colorName: 'success',
    value: 20,
    name: "Shell Prime Derv Non Core"
  }];
  _exports.DASHBOARD_LOCATIONS = DASHBOARD_LOCATIONS;
  var CREDIT_LIMIT = [{
    name: 'Uk Fuels',
    percentage: 50,
    used: 13,
    color: colorNameToHex['success']
  }, {
    name: 'BP Bunker',
    percentage: 30,
    used: 5,
    color: colorNameToHex['primary']
  }, {
    name: 'Shell Prime',
    percentage: 10,
    used: 1,
    color: colorNameToHex['warning']
  }, {
    name: 'Uk Fuels 2',
    percentage: 20,
    used: 7,
    color: colorNameToHex['accent']
  }];
  _exports.CREDIT_LIMIT = CREDIT_LIMIT;

  var ShapeCards = function ShapeCards(credits, selected) {
    var initIndex = credits.map(function (credit) {
      return credit.name;
    }).indexOf(selected.name);
    var firstItem = null;
    var lastItem = null;

    if (initIndex === 0) {
      firstItem = credits[credits.length - 1];
    } else {
      firstItem = credits[initIndex - 1];
    }

    if (credits.length - 1 === initIndex) {
      lastItem = credits[0];
    } else {
      lastItem = credits[initIndex + 1];
    }

    return [firstItem, selected, lastItem];
  };

  _exports.ShapeCards = ShapeCards;

  var ShapeLocations = function ShapeLocations(locations) {
    return locations.map(function (location) {
      return _objectSpread(_objectSpread({}, location), {}, {
        style: {
          'background-color': location.color
        }
      });
    });
  };

  _exports.ShapeLocations = ShapeLocations;
});