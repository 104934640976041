define("fuel-portal/templates/components/card/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tnKbIrnB",
    "block": "{\"symbols\":[\"@loading\",\"@card\"],\"statements\":[[1,[30,[36,0],[[32,2,[\"componentPath\"]]],[[\"card\",\"loading\"],[[32,2],[32,1]]]]]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "fuel-portal/templates/components/card/preview.hbs"
    }
  });

  _exports.default = _default;
});