define("fuel-portal/utils/exports/export-by-id", ["exports", "fuel-portal/utils/generate-url", "fuel-portal/utils/get-api-url"], function (_exports, _generateUrl, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = exportById;

  function exportById(modelType, resourceType, id) {
    var url = (0, _generateUrl.generateUrl)((0, _getApiUrl.getApiUrlWithoutNamespace)(), 'restapi', 'v1', modelType, id, 'download', resourceType);
    var queryParameters = (0, _generateUrl.generateQueryParameters)("jwtToken", localStorage.getItem('auth_token'));
    window.open(url + '/' + queryParameters);
  }
});