define("fuel-portal/utils/shape-params-cards", ["exports", "fuel-portal/utils/shape-params", "lodash"], function (_exports, _shapeParams, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapeParams;

  //Private functions
  function shapeNumber(params) {
    if (!Ember.isEmpty(params.number)) {
      var number = params.number;
      params.number = {};
      params.number.LIKE = '%' + number + '%'; // if (params['number'].length === 4)
      //   params['number'] = '**************' + params['number'] + '*';
      // else
      //   params['number'] = '**************' + params['number'];
    }
  }

  function shapeStatus(params) {
    if (!Ember.isEmpty(params['formattedStatus'])) {
      if (params['formattedStatus'] === 'Stopped') {
        params['status'] = 'PermanentStopped';
      } else if (params['formattedStatus'] === 'Temp Stop') {
        params['status'] = 'TemporaryStopped';
      } else if (params['formattedStatus'] === 'Pending Temp Stop') {
        params['status'] = 'PendingTemporaryStop';
      } else if (params['formattedStatus'] === 'Pending Stop') {
        params['status'] = 'PendingPermanentStop';
      } else if (params['formattedStatus'] === 'Pending Active') {
        params['status'] = 'PendingActive';
      } else {
        params['status'] = params['formattedStatus'];
      }

      delete params['formattedStatus'];
    }
  }

  function shapeType(params) {
    if (!Ember.isEmpty(params['type'])) {
      params['type'] = params['type'].toUpperCase();
    }
  } //Public functions


  function shapeParams(params) {
    var shapeExpiry = _lodash.default.curry(_shapeParams.shapeDateQueryParam)('expiry');

    return (0, _shapeParams.shapeReducer)(shapeExpiry, shapeStatus, shapeNumber, shapeType)(params);
  }
});