define("fuel-portal/components/ui/flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="
   border-l-4 p-4 w-full
   bg-gray-900 border-gray-500
  ">
    <div class="flex text-white">
      <div class="flex-shrink-0">
        {{#if (eq @flash.type "success")}}
          {{svg-jar "speakerphone" class="h-5 w-5"}}
        {{else if (eq @flash.type "warning")}}
          {{svg-jar "exclamation" class="h-5 w-5"}}
        {{else if (eq @flash.type "alert")}}
          {{svg-jar "exclamation-circle" class="h-5 w-5"}}
        {{else }}
          {{svg-jar "chat-alt" class="h-5 w-5"}}
        {{/if}}
      </div>
      <div class="ml-3">
        <p class="text-sm leading-5">
          {{@flash.message}}
        </p>
      </div>
    </div>
  </div>
  */
  {"id":"/mC+UiE3","block":"{\"symbols\":[\"@flash\"],\"statements\":[[10,\"div\"],[14,0,\"\\n border-l-4 p-4 w-full\\n bg-gray-900 border-gray-500\\n\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex text-white\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-shrink-0\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"type\"]],\"success\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"speakerphone\"],[[\"class\"],[\"h-5 w-5\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"type\"]],\"warning\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"exclamation\"],[[\"class\"],[\"h-5 w-5\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"type\"]],\"alert\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"exclamation-circle\"],[[\"class\"],[\"h-5 w-5\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"chat-alt\"],[[\"class\"],[\"h-5 w-5\"]]]],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ml-3\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-sm leading-5\"],[12],[2,\"\\n        \"],[1,[32,1,[\"message\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"eq\",\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/flash.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});