define("fuel-portal/components/ui/card/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex  flex-col sm:flex-row p-2">
    <div class="w-full p-2 md:pr-4 sm:w-68 md:border-gray-100 md:border-r">
      <Card::Preview @card={{@card}}
        @loading={{@loading}} />
    </div>
    <div class="p-2 md:pl-4 flex-1 space-y-4 font-light">
      <p>All fuel cards are issued and managed by Fuelmate Limited for the purchase of products and/or services on behalf of the relevant Network providers.</p>
      <p>By use of the card the Customer acknowledges the latest Terms and Conditions associated with the account.</p>
      <p>Terms and Conditions can be found by visiting <a href="https://www.fuelmate.co.uk/terms-conditions/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary-800">https://www.fuelmate.co.uk/terms-conditions/</a>.</p>
    </div>
  </div>
  */
  {"id":"7CbtWrkE","block":"{\"symbols\":[\"@card\",\"@loading\"],\"statements\":[[10,\"div\"],[14,0,\"flex  flex-col sm:flex-row p-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-full p-2 md:pr-4 sm:w-68 md:border-gray-100 md:border-r\"],[12],[2,\"\\n    \"],[8,\"card/preview\",[],[[\"@card\",\"@loading\"],[[32,1],[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"p-2 md:pl-4 flex-1 space-y-4 font-light\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"All fuel cards are issued and managed by Fuelmate Limited for the purchase of products and/or services on behalf of the relevant Network providers.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"By use of the card the Customer acknowledges the latest Terms and Conditions associated with the account.\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Terms and Conditions can be found by visiting \"],[10,\"a\"],[14,6,\"https://www.fuelmate.co.uk/terms-conditions/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"text-primary-800\"],[12],[2,\"https://www.fuelmate.co.uk/terms-conditions/\"],[13],[2,\".\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"fuel-portal/components/ui/card/summary.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});