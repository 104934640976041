define("fuel-portal/mixins/validations/card", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('presence', true)],
    holderType: [(0, _emberCpValidations.validator)('presence', true)],
    name: [(0, _emberCpValidations.validator)('presence', true)],
    numberOfCards: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0
    })]
  });

  _exports.default = _default;
});