define("fuel-portal/mirage/factories/cardtypeusage", ["exports", "ember-cli-mirage", "fuel-portal/utils/const/card"], function (_exports, _emberCliMirage, _card) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    cardCount: function cardCount() {
      return _emberCliMirage.faker.random.number({
        min: 1,
        max: 20
      });
    },
    cardUsedCount: function cardUsedCount() {
      return this.cardCount - _emberCliMirage.faker.random.number({
        min: 1,
        max: this.cardCount - 1
      });
    },
    type: function type() {
      return _emberCliMirage.faker.random.arrayElement(_card.CARD_TYPE_OPTIONS);
    },
    network: function network() {
      return _emberCliMirage.faker.random.arrayElement(_card.CARD_NETWORKS);
    }
  });

  _exports.default = _default;
});