define("fuel-portal/mirage/serializers/user", ["exports", "fuel-portal/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'Id',
    searchByFields: Ember.A(['firstName', 'lastName', 'Id'])
  });

  _exports.default = _default;
});