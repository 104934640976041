define("fuel-portal/utils/shape-params-transactions", ["exports", "fuel-portal/utils/shape-params", "lodash"], function (_exports, _shapeParams, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapeParams;

  //Public functions
  function shapeParams(params) {
    // rename date range param
    params.date = params.range;
    delete params.range;

    var shapeDate = _lodash.default.curry(_shapeParams.shapeDateQueryParam)('date');

    return (0, _shapeParams.shapeReducer)(shapeDate)(params);
  }
});