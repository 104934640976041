define("fuel-portal/tailwind/fuelmate-config", [], function () {
  "use strict";

  /* global module, require */
  // const defaultTheme = require('tailwindcss/defaultTheme');
  module.exports = {
    prefix: '',
    important: false,
    separator: ':',
    theme: {
      fontFamily: {
        'sans': ['Lato', 'sans-serif'],
        'serif': ['Martel', 'serif']
      },
      extend: {
        boxShadow: {
          bottom: '0 4px 4px -4px rgba(0, 0, 0, 0.05)'
        },
        colors: {
          'primary': {
            50: '#F4FCF9',
            100: '#E9FAF3',
            200: '#C9F2E2',
            300: '#A9EAD1',
            400: '#68DAAE',
            500: '#27CA8B',
            600: '#23B67D',
            700: '#177953',
            800: '#125B3F',
            900: '#0C3D2A'
          },
          'green': {
            50: '#F4FCF9',
            100: '#E9FAF3',
            200: '#C9F2E2',
            300: '#A9EAD1',
            400: '#68DAAE',
            500: '#27CA8B',
            600: '#23B67D',
            700: '#177953',
            800: '#125B3F',
            900: '#0C3D2A'
          },
          'blue': {
            50: '#F2F7F9',
            100: '#E6EFF3',
            200: '#C0D6E0',
            300: '#9BBECD',
            400: '#4F8DA8',
            500: '#045C82',
            600: '#045375',
            700: '#02374E',
            800: '#02293B',
            900: '#011C27'
          },
          'orange': {
            50: '#FFFAF2',
            100: '#FEF6E6',
            200: '#FDE7BF',
            300: '#FBD999',
            400: '#F9BD4D',
            500: '#F6A000',
            600: '#DD9000',
            700: '#946000',
            800: '#6F4800',
            900: '#4A3000'
          },
          'red': {
            50: '#FEF4F5',
            100: '#FCE9EA',
            200: '#F9C7CB',
            300: '#F5A5AB',
            400: '#ED626D',
            500: '#E51F2E',
            600: '#CE1C29',
            700: '#89131C',
            800: '#670E15',
            900: '#45090E'
          },
          'yellow': {
            50: '#FFFDF3',
            100: '#FEFBE7',
            200: '#FDF4C4',
            300: '#FBEDA1',
            400: '#F8E05A',
            500: '#F5D313',
            600: '#DDBE11',
            700: '#937F0B',
            800: '#6E5F09',
            900: '#4A3F06'
          },
          'gray': {
            50: '#F5F5F5',
            100: '#EDEDED',
            200: '#D5D5D5',
            300: '#ACACAB',
            400: '#8E8E8E',
            500: '#6D6D6D',
            600: '#2F2F2E',
            700: '#1C1C1C',
            800: '#151515',
            900: '#0E0E0E'
          }
        },
        spacing: {
          '18': '4.5rem',
          '68': '17rem',
          '72': '18rem',
          '80': '20rem',
          '88': '22rem',
          '96': '24rem',
          '104': '26rem',
          '112': '28rem',
          '120': '30rem',
          '128': '32rem'
        },
        maxHeight: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '72': '18rem',
          '80': '20rem'
        },
        minHeight: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '72': '18rem',
          '80': '20rem'
        },
        maxWidth: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '72': '18rem',
          '80': '20rem',
          'xs': '20rem',
          // 320px
          'sm': '40rem',
          // 640px
          'md': '48rem',
          // 768px
          'lg': '64rem',
          // 1024px
          'xl': '80rem' // 1280px

        },
        minWidth: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '72': '18rem',
          '80': '20rem',
          'xs': '20rem',
          // 320px
          'sm': '40rem',
          // 640px
          'md': '48rem',
          // 768px
          'lg': '64rem',
          // 1024px
          'xl': '80rem' // 1280px

        }
      }
    },
    corePlugins: {},
    plugins: [require("@tailwindcss/ui")({
      layout: 'sidebar'
    }), require("tailwindcss-ember-power-select").plugin(function (_ref) {
      var theme = _ref.theme;
      return {
        // Radius
        triggerBorderRadius: theme('borderRadius.md'),
        dropdownBorderRadius: theme('borderRadius.md'),
        searchInputBorderRadius: theme('borderRadius.md'),
        multipleOptionBorderRadius: theme('borderRadius.md'),
        openedBorderRadius: theme('borderRadius.md'),
        // Border
        borderColor: theme('colors.gray.300'),
        focusBorderColor: theme('colors.orange.300'),
        disabledBorderColor: theme('color.gray.300'),
        invalidBorderColor: theme('color.red.600'),
        // BG
        triggerFocusBoxShadow: theme('boxShadow.outline-orange'),
        selectedBackgroundColor: theme('colors.green.300'),
        multipleOptionBackgroundColor: theme('colors.green.600'),
        highlightedBackgroundColor: theme('colors.green.600')
      };
    })]
  };
});