define("fuel-portal/components/ui/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="
    md:z-20 md:sticky md:top-0
    flex flex-row flex-wrap md:flex-nowrap
    bg-primary-600 text-white
    md:h-18
    text-base font-bold leading-6 md:leading-8
    px-6 md:px-12 py-5">
    {{yield (hash
      Item=(component "ui/breadcrumbs/item")
    )}}
  </div>
  */
  {"id":"LdGjx+ox","block":"{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"\\n  md:z-20 md:sticky md:top-0\\n  flex flex-row flex-wrap md:flex-nowrap\\n  bg-primary-600 text-white\\n  md:h-18\\n  text-base font-bold leading-6 md:leading-8\\n  px-6 md:px-12 py-5\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"Item\"],[[30,[36,0],[\"ui/breadcrumbs/item\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}","meta":{"moduleName":"fuel-portal/components/ui/breadcrumbs.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});