define("fuel-portal/helpers/feature-enabled", ["exports", "fuel-portal/utils/feature-enabled"], function (_exports, _featureEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function featureEnabled(params
  /*, hash*/
  ) {
    return (0, _featureEnabled.default)(params[0]);
  });

  _exports.default = _default;
});