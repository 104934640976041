define("fuel-portal/helpers/format-quantity", ["exports", "fuel-portal/utils/quantity-formatter"], function (_exports, _quantityFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatQuantity(params
  /*, hash*/
  ) {
    return (0, _quantityFormatter.default)(params[0]);
  });

  _exports.default = _default;
});