define("fuel-portal/components/ui/data/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="font-base text-black leading-5 px-2 py-4 border-b border-gray-100"
    ...attributes>
    {{#if @label}}
      <div class="font-black mb-2">{{@label}}</div>
    {{/if}}
  
    <div class="font-light mb-2">
      {{#if @loading}}
  
        <div class="bg-gray-100 h-5 w-1/2 rounded-sm"></div>
  
      {{else}}
        {{#if hasBlock}}
          {{yield}}
        {{else if @value}}
          {{@value}}
        {{else}}
          N/A
        {{/if}}
      {{/if}}
    </div>
  
    {{#if @tip}}
      <div class="font-light text-xs italic">{{@tip}}</div>
    {{/if}}
  </div>
  */
  {"id":"zAIomvk/","block":"{\"symbols\":[\"@tip\",\"@value\",\"&default\",\"@label\",\"&attrs\",\"@loading\"],\"statements\":[[11,\"div\"],[24,0,\"font-base text-black leading-5 px-2 py-4 border-b border-gray-100\"],[17,5],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"font-black mb-2\"],[12],[1,[32,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"font-light mb-2\"],[12],[2,\"\\n\"],[6,[37,0],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"bg-gray-100 h-5 w-1/2 rounded-sm\"],[12],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        N/A\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"font-light text-xs italic\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/data/item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});