define("fuel-portal/helpers/theme", ["exports", "fuel-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function theme(params
  /*, hash*/
  ) {
    return _environment.default.isWhiteLabel ? params[1] : params[0];
  });

  _exports.default = _default;
});