define("fuel-portal/templates/internal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NjdgQEvc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"h-screen flex overflow-hidden bg-gray-50\"],[12],[2,\"\\n\\n\"],[2,\"  \"],[8,\"ui/mobile-nav\",[],[[\"@items\",\"@statementsEnabled\",\"@isShowingNav\",\"@onClose\"],[[32,0,[\"options\"]],[32,0,[\"statementsEnabled\"]],[32,0,[\"isShowingNav\"]],[32,0,[\"toggleNav\"]]]],null],[2,\"\\n\\n\"],[2,\"  \"],[10,\"div\"],[14,1,\"slideover-wormhole\"],[12],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[2,\"  \"],[8,\"ui/side-nav\",[],[[\"@items\",\"@statementsEnabled\"],[[32,0,[\"options\"]],[32,0,[\"statementsEnabled\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-col w-0 flex-1 overflow-hidden\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"relative md:hidden p-4 bg-gray-100 flex-shrink-0 flex items-center justify-center\"],[12],[2,\"\\n\\n      \"],[11,\"button\"],[24,0,\"absolute inset-y-0 left-0 mx-2 my-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-600 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150\"],[24,\"aria-label\",\"Open sidebar\"],[24,\"data-test-nav-menu\",\"open\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"toggleNav\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"menu\"],[[\"class\"],[\"h-6 w-6 text-black\"]]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[1,[30,[36,1],[[30,[36,2],[\"logo-color\",\"bytestrype\"],null]],[[\"class\"],[\"h-10 w-auto text-black\"]]]],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[10,\"main\"],[14,0,\"flex-1 relative z-0 overflow-y-auto focus:outline-none\"],[14,\"tabindex\",\"0\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"min-h-screen flex flex-col flex-1\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\\n        \"],[8,\"ui/footer\",[],[[],[]],null],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"svg-jar\",\"theme\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "fuel-portal/templates/internal.hbs"
    }
  });

  _exports.default = _default;
});