define("fuel-portal/utils/shape-params-users", ["exports", "fuel-portal/utils/shape-params"], function (_exports, _shapeParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapeParams;

  function shapeSearch(params) {
    if (!Ember.isEmpty(params.search)) {
      var arr = params.search.split(" ");
      params.firstName = {};
      params.firstName.LIKE = '%' + arr[0] + '%';

      if (arr.length > 1) {
        params.lastName = {};
        params.lastName.LIKE = '%' + arr[1] + '%';
      }

      delete params.search;
    }
  } //Public functions


  function shapeParams(params) {
    return (0, _shapeParams.shapeReducer)(shapeSearch)(params);
  }
});