define("fuel-portal/utils/get-sort-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSortParams;

  function getSortParams(params) {
    var sort = params.sort;
    delete params.sort;
    return sort;
  }
});