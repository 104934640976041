define("fuel-portal/helpers/transaction-amount-formatter", ["exports", "fuel-portal/utils/currency-formatter", "fuel-portal/utils/quantity-formatter"], function (_exports, _currencyFormatter, _quantityFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function transactionAmountFormatter(params
  /*, hash*/
  ) {
    var transaction = params[0],
        round = params[1];

    if (transaction) {
      return transaction.amountType == "currency" ? (0, _currencyFormatter.default)(transaction.amountCurrency, round) : (0, _quantityFormatter.default)(transaction.amountQuantity, round);
    } else {
      return "";
    }
  });

  _exports.default = _default;
});