define("fuel-portal/utils/shape-params-invoices", ["exports", "fuel-portal/utils/shape-params", "lodash"], function (_exports, _shapeParams, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shapeParams;

  //Private functions
  function shapeNumber(params) {
    if (!Ember.isEmpty(params['number'])) {
      var length = params['number'].length;
      if (params['number'].slice(0, 1) === "#") params['number'] = params['number'].slice(1, length);
    }
  }

  function shapeGrossValue(params) {
    if (!Ember.isEmpty(params['grossValue'])) {
      var value = params['grossValue'];

      if ((value % 1).toFixed(2) < 0.01) {
        params['grossValue'] = {};
        params['grossValue']['GT'] = value - 0.01;
        params['grossValue']['LT'] = parseInt(value) + 1;
      }

      if (parseFloat(value).toFixed(1) == value) {
        params['grossValue'] = {};
        params['grossValue']['GT'] = value - 0.01;
        params['grossValue']['LT'] = parseFloat(value) + 0.1;
      }
    }
  } // function shapeType(params) {
  //   if(!isEmpty(params['type'])) { 
  //     params['type'] = params['type'].toUpperCase();
  //   }
  // }
  //Public functions


  function shapeParams(params) {
    var shapeDate = _lodash.default.curry(_shapeParams.shapeDateQueryParam)('date');

    var shapeDueDate = _lodash.default.curry(_shapeParams.shapeDateQueryParam)('dueDate');

    return (0, _shapeParams.shapeReducer)(shapeDate, shapeDueDate, shapeNumber, shapeGrossValue)(params);
  }
});