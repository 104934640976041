define("fuel-portal/utils/currency-formatter", ["exports", "currency-formatter"], function (_exports, _currencyFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(currency, round) {
    if (round) {
      currency = Math.round(currency);
    }

    return _currencyFormatter.default.format(currency, {
      code: "GBP"
    });
  }
});