define("fuel-portal/mirage/factories/accountbalance", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    creditLimit: function creditLimit() {
      return _emberCliMirage.faker.random.number({
        min: 500,
        max: 20000
      });
    },
    balance: function balance() {
      return this.creditLimit - _emberCliMirage.faker.random.number({
        min: 10,
        max: this.creditLimit - 10
      });
    },
    currency: 'GBP',
    unposted: function unposted() {
      return this.creditLimit - _emberCliMirage.faker.random.number({
        min: 10,
        max: this.creditLimit - 10
      });
    }
  });

  _exports.default = _default;
});