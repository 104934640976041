define("fuel-portal/utils/quantity-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(quantity, round) {
    if (round) {
      quantity = Math.round(quantity);
    }

    return quantity + "L";
  }
});