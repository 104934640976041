define("fuel-portal/components/ui/account/crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Breadcrumbs as
    |crumb|>
  
    {{#if @account}}
  
      <crumb.Item>
        <LinkTo @route="internal.accounts.index">
          Accounts
        </LinkTo>
      </crumb.Item>
  
      <crumb.Item>
        <LinkTo @route="internal.account.index"
          @model={{@account.id}}>
          {{if @loading "Loading..." @account.name}}
        </LinkTo>
      </crumb.Item>
    {{/if}}
  
    <crumb.Item @isLast={{true}}>
      {{@page}}
    </crumb.Item>
  
  </Ui::Breadcrumbs>
  */
  {"id":"jjgPRAAc","block":"{\"symbols\":[\"crumb\",\"@account\",\"@loading\",\"@page\"],\"statements\":[[8,\"ui/breadcrumbs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"internal.accounts.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Accounts\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"Item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"internal.account.index\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,3],\"Loading...\",[32,2,[\"name\"]]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Item\"]],[],[[\"@isLast\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/account/crumbs.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});