define("fuel-portal/components/ui/side-nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Link class="relative w-full mb-2 group flex items-center p-2 pl-12 text-lg leading-6 font-medium text-black hover:text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:text-black focus:bg-gray-200 transition ease-in-out duration-150"
    @activeClass="bg-gray-50"
    @inactiveClass="hover:bg-gray-100"
    @route={{@route}}
    @activeWhen={{@activeWhen}}
    @href={{@href}}
    ...attributes>
    {{#if @icon}}
      <Ui::SideNav::Icon @icon={{@icon}} />
    {{/if}}
    {{yield}}
  
  </Ui::Link>
  */
  {"id":"BwYQ9tYy","block":"{\"symbols\":[\"@icon\",\"@route\",\"@activeWhen\",\"@href\",\"&attrs\",\"&default\"],\"statements\":[[8,\"ui/link\",[[24,0,\"relative w-full mb-2 group flex items-center p-2 pl-12 text-lg leading-6 font-medium text-black hover:text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:text-black focus:bg-gray-200 transition ease-in-out duration-150\"],[17,5]],[[\"@activeClass\",\"@inactiveClass\",\"@route\",\"@activeWhen\",\"@href\"],[\"bg-gray-50\",\"hover:bg-gray-100\",[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui/side-nav/icon\",[],[[\"@icon\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,6,null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"fuel-portal/components/ui/side-nav/item.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});