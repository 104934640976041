define("fuel-portal/utils/const/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CARD_STATUS_OPTIONS = _exports.CARD_HOLDER_TYPE_MAP = _exports.CARD_HOLDER_TYPES = _exports.CARD_NETWORKS = _exports.CARD_TYPE_OPTIONS = _exports.CARD_EXPORT_PATH = _exports.CARD_SORT_OPTIONS = _exports.CARD_FILTER_ATTRS = _exports.CARD_DETAIL_ACTIONS = void 0;
  var CARD_DETAIL_ACTIONS = [{
    title: "View Card Details",
    route: "internal.card.index"
  }, {
    title: "View Transactions",
    route: "internal.card.transactions"
  }, {
    title: "Block/Unblock Card",
    route: "internal.card.block"
  }, {
    title: "Cancel Card",
    route: "internal.card.cancel"
  }];
  _exports.CARD_DETAIL_ACTIONS = CARD_DETAIL_ACTIONS;
  var CARD_FILTER_ATTRS = ['number', 'type', 'formattedStatus', 'formattedExpireDate', 'driver', 'vrn', 'dateLastUsed'];
  _exports.CARD_FILTER_ATTRS = CARD_FILTER_ATTRS;
  var CARD_SORT_OPTIONS = [{
    label: "Driver",
    value: "driver"
  }, {
    label: "Vehicle",
    value: "vrn"
  }, {
    label: "Card Number",
    value: "number"
  }];
  _exports.CARD_SORT_OPTIONS = CARD_SORT_OPTIONS;
  var CARD_EXPORT_PATH = "/cards/csv";
  _exports.CARD_EXPORT_PATH = CARD_EXPORT_PATH;
  var CARD_TYPE_OPTIONS = [// "Default",
  "BP Bunker", "BP List", "BP Plus", "D Direct", "Esso", "Esso Truck", "FastFuels", "Flexi-Pay", "fuelGenie", "Shell CRT", "Shell Flt", "ShellFlt2", "ShellMulti", "ShellPrime", "UK Fuels"];
  _exports.CARD_TYPE_OPTIONS = CARD_TYPE_OPTIONS;
  var CARD_NETWORKS = ["BP", "Esso", "Shell"];
  _exports.CARD_NETWORKS = CARD_NETWORKS;
  var CARD_HOLDER_TYPES = ["Driver", "Vehicle Registration"];
  _exports.CARD_HOLDER_TYPES = CARD_HOLDER_TYPES;
  var CARD_HOLDER_TYPE_MAP = {
    "Driver": "driver",
    "Vehicle Registration": "vrn"
  };
  _exports.CARD_HOLDER_TYPE_MAP = CARD_HOLDER_TYPE_MAP;
  var CARD_STATUS_OPTIONS = ["Active", "Temp Stop", "Stopped"];
  _exports.CARD_STATUS_OPTIONS = CARD_STATUS_OPTIONS;
});