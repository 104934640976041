define("fuel-portal/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sl37cK3g",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[1,[30,[36,1],[[30,[36,0],[\"Fuelmate\",\"Bytestrype\"],null]],null]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\n\"],[8,\"jivo-chat\",[],[[],[]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"fixed bottom-0 z-50 sm:p-4 w-full pointer-events-none\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"flashMessages\",\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui/flash\",[],[[\"@flash\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"theme\",\"page-title\",\"-outlet\",\"component\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "fuel-portal/templates/application.hbs"
    }
  });

  _exports.default = _default;
});