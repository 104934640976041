define("fuel-portal/templates/components/card/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXSA2bAe",
    "block": "{\"symbols\":[\"@card\"],\"statements\":[[10,\"img\"],[15,\"src\",[32,1,[\"cardAvatarPath\"]]],[14,\"alt\",\"Credit Card\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "fuel-portal/templates/components/card/avatar.hbs"
    }
  });

  _exports.default = _default;
});