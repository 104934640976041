define("fuel-portal/components/card/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    base: 'card-avatar'
  }).reopenClass({
    positionalParams: ['card']
  });

  _exports.default = _default;
});