define("fuel-portal/helpers/format-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatVolume(params
  /*, hash*/
  ) {
    var volume = parseFloat(params[0]);
    var unit = "lts";

    if (isNaN(volume)) {
      return "n/a";
    }

    if (volume === 1) {
      unit = "ltr";
    }

    return "".concat(volume).concat(unit);
  });

  _exports.default = _default;
});