define("fuel-portal/utils/shape-params", ["exports", "fuel-portal/utils/date-utilities", "lodash"], function (_exports, _dateUtilities, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shapeDateQueryParam = shapeDateQueryParam;
  _exports.shapeReducer = shapeReducer;
  _exports.default = shapeParams;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  //Private functions
  function removeEmptyParams(params) {
    var newParams = {};

    for (var prop in params) {
      if (!Ember.isEmpty(params[prop])) {
        newParams[prop] = params[prop];
      }
    }

    return newParams;
  } //Shapers may modify the existing state, or return a new object
  //this takes the result of the shaper and works out whether it needs
  //to return the existing params or new results


  function resultNormalizer(shaper) {
    return function (params) {
      var result = shaper(params);

      if (result) {
        return result;
      } else {
        return params;
      }
    };
  } //Public functions


  function shapeDateQueryParam(propertyName, params) {
    if (!Ember.isEmpty(params[propertyName])) {
      var actualDate = (0, _dateUtilities.uiDateToActualDate)(params[propertyName]);
      params[propertyName] = {};
      params[propertyName]['GE'] = actualDate.from;
      params[propertyName]['LE'] = actualDate.to;
    }
  }

  function shapeReducer() {
    //Create an initial shaper that takes the parameters
    //and and maps to a new objects to create purity (no modification of outside parameter state)
    var purifyParams = function purifyParams(params) {
      return _objectSpread({}, params);
    }; //Get any additional shapers (if this is called from the card shaper etc)


    var additionalShapers = Array.prototype.slice.call(arguments); //Composition of purification, base shaper functions and shapers from cards etc.

    var args = [purifyParams, resultNormalizer(removeEmptyParams)].concat(_toConsumableArray(additionalShapers.map(function (shaper) {
      return resultNormalizer(shaper);
    })));
    return _lodash.default.flow(args);
  }

  function shapeParams(params) {
    return shapeReducer()(params);
  }
});