define("fuel-portal/mirage/config", ["exports", "fuel-portal/mirage/helpers/session-handlers", "fuel-portal/mirage/helpers/responses", "fuel-portal/config/environment"], function (_exports, _sessionHandlers, _responses, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // import Response from 'ember-cli-mirage/response';
  function _default() {
    /*
      Config (with defaults).
      Note: these only affect routes defined *after* them!
    */
    // make this `http://localhost:8080`, for example, if your API is on a different server
    this.urlPrefix = _environment.default.rootApiURL; // make this `api`, for example, if your API is namespaced

    this.namespace = _environment.default.apiNamespace; // delay for each request, automatically set to 0 during testing

    this.timing = 1000; // Authentication

    this.get("/session", _sessionHandlers.getSession);
    this.post("http://localhost:4000/authenticate", _sessionHandlers.postSession);
    this.delete("/session", _sessionHandlers.deleteSession); // Passwords

    this.post("/recover-password", function () {
      return (0, _responses.successResponse)(202);
    });
    this.put("/reset-password", function () {
      return (0, _responses.successResponse)(204);
    });
    this.get("/users");
    this.post("/users");
    this.get("/users/:id", function (schema, request) {
      var id = request.params.id;
      return schema.users.findBy({
        Id: id
      });
    });
    this.patch("/users/:id");
    this.del("/users/:id");
    this.get("/accounts");
    this.get("/accounts/:id");
    this.get("/volumebyweeks");
    this.get("/cardtypeusages");
    this.get("/accountbalances");
    this.get("/transactions");
    this.get("/transactions/:id");
    this.get("/invoices");
    this.get("/invoices/:id");
    this.get("/cards");
    this.namespace = "restapi/v1";
    this.post("/cards/ordernew", function (schema, request) {
      var body = JSON.parse(request.requestBody);
      return schema.cards.create({
        body: body
      }); // return successResponse(201, "OK");
    });
    this.post("/cards/:id/pinreminder", function ()
    /*schema, request*/
    {
      return (0, _responses.successResponse)(201, "OK");
    });
    this.namespace = "jsonapi/v1";
    this.get("/cards/:id");
    this.patch("/cards/:id");
    this.del("/cards/:id");
    this.passthrough("https://api.mapbox.com/**");
    this.passthrough("https://events.mapbox.com/**");
    this.passthrough("mapbox://**");
    this.passthrough("https://node226.jivosite.com/**");
    this.passthrough("https://code.jivosite.com/**");
    this.passthrough("https://telemetry.jivosite.com/**");
    this.passthrough("https://*.jivosite.com/**");
    this.passthrough("https://node231.jivosite.com/**");
    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */
    // Presign

    /**
     * Custom Route handle to mock a presign request
     */

    this.post("/presigns", function (schema, request) {
      var signature = "testsig",
          body = JSON.parse(request.requestBody),
          signedResource = body.data.attributes.resource + "&signature=".concat(signature);
      return schema.presigns.create({
        resource: body.resource,
        signature: signature,
        signedResource: signedResource
      });
    });
  }
});