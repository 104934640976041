define("fuel-portal/mirage/serializers/application", ["exports", "ember-mirage-sauce/mirage-serializers/json-api-serializer"], function (_exports, _jsonApiSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApiSerializer.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      return key;
    },

    /**
      Paginate response
      @access protected
      @method paginate
      @param {object} results data to be paginated
      @param {number} page  current page
      @param {number} size  current page size
      @return {object}
    */
    paginate: function paginate(res, page, size) {
      var slicedResults = function slicedResults(results) {
        var start = (page - 1) * size;
        var end = start + size;
        return results.slice(start, end);
      };

      var buildMetadata = function buildMetadata(results) {
        return {
          totalResourceCount: results.length
        };
      };

      res.meta = buildMetadata(res.data);
      res.data = slicedResults(res.data);
      return res;
    }
  });

  _exports.default = _default;
});