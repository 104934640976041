define("fuel-portal/utils/cards/cancel-card", ["exports", "jquery", "fuel-portal/utils/get-api-url"], function (_exports, _jquery, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cancelCard;

  function cancelCard(card) {
    var url = (0, _getApiUrl.getApiUrlWithoutNamespace)('/restapi/v1/cards/' + card.id + '/cancel');
    return new Ember.RSVP.Promise(function (resolve, reject) {
      _jquery.default.ajax({
        type: 'POST',
        url: url,
        data: null
      }).then(function () {
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
});