define("fuel-portal/tailwind/whitelabel-config", [], function () {
  "use strict";

  /* global module, require */
  var defaultTheme = require("tailwindcss/defaultTheme");

  module.exports = {
    prefix: '',
    important: false,
    separator: ':',
    theme: {
      fontFamily: {
        'sans': ['Lato', 'sans-serif'],
        'serif': ['Martel', 'serif']
      },
      extend: {
        boxShadow: {
          bottom: '0 4px 4px -4px rgba(0, 0, 0, 0.05)'
        },
        colors: {
          primary: defaultTheme.colors.teal
        },
        spacing: {
          '18': '4.5rem',
          '68': '17rem',
          '72': '18rem',
          '80': '20rem',
          '88': '22rem',
          '96': '24rem',
          '104': '26rem',
          '112': '28rem',
          '120': '30rem',
          '128': '32rem'
        },
        maxHeight: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '80': '20rem'
        },
        minHeight: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '80': '20rem'
        },
        maxWidth: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '80': '20rem',
          'xs': '20rem',
          // 320px
          'sm': '40rem',
          // 640px
          'md': '48rem',
          // 768px
          'lg': '64rem',
          // 1024px
          'xl': '80rem' // 1280px

        },
        minWidth: {
          '0': '0',
          '4': '1rem',
          '8': '2rem',
          '16': '4rem',
          '24': '6rem',
          '32': '8rem',
          '40': '10rem',
          '48': '12rem',
          '56': '14rem',
          '64': '16rem',
          '80': '20rem',
          'xs': '20rem',
          // 320px
          'sm': '40rem',
          // 640px
          'md': '48rem',
          // 768px
          'lg': '64rem',
          // 1024px
          'xl': '80rem' // 1280px

        }
      }
    },
    corePlugins: {},
    plugins: [require("@tailwindcss/ui")({
      layout: 'sidebar'
    }), require("tailwindcss-ember-power-select").plugin(function (_ref) {
      var theme = _ref.theme;
      return {
        borderColor: theme('colors.gray.300'),
        focusBorderColor: theme('colors.orange.300'),
        disabledBorderColor: theme('color.gray.300'),
        invalidBorderColor: theme('color.red.600')
      };
    })]
  };
});